import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as PR from "../../../prime-modules/index";
import "./TopupEsims.scss";
import { getCatalogsList, getCountriesList } from "../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { plausible } from "../../../utils/plausibleTracker";
import { cartFilterActions } from "../../../store/cart";
import { geteSIMsDescription, renderPrice, setNumberPrecision } from "../../../utils/reuse";
import EsimStatusHeader from "./EsimStatusHeader";
import MakePayment from "../../../components/payment/MakePayment";
import { useToast } from "../../../context/ToastContext";
import { useLocation, useNavigate } from "react-router-dom";

const TopupEsims = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showToast, labels } = useToast();
  const getCartData = useSelector((state) => state.cart.catalogCart);
  const iccid = useSelector((state) => state.esim.esimIccid);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const [loading, setLoading] = useState(false);
  const [countryVal, setCountryVal] = useState(true);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [catalogs, setCatalogs] = useState([]);
  const [direction, setDirection] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const filter = "";
  const filterBy = "";
  const page = "";
  const limit = "";
  const [checkoutFormState, setCheckoutFormState] = useState(false);
  const [doNotSell, setDoNotSell] = useState(false)
  const trackCountry = useCallback((countryName) => {
    plausible.trackEvent("country", { props: { country: countryName } });
  }, []);

  const clearStoreContent = useCallback(() => {
    dispatch(cartFilterActions.deleteCatalogCart());
    dispatch(cartFilterActions.setCatalogCartAmount(0));
  }, [dispatch]);

  const getCountryData = useCallback(() => {
    const getCountryData = (response) => {
      if (response.result === "SUCCESS") {
        if (response.data?.length > 0) {
          const countriesList = response.data?.map((c) => ({
            name: c.name.split("(")[0]?.trim(),
            region: c.region,
            iso: c.iso,
          }));
          setCountry(countriesList[0]?.iso);
          setCountries(countriesList);
          trackCountry(countriesList[0]?.name);
          setDirection(1);
          setOrderBy("price");
        } else {
          showToast("info", "ESMBND-03");
        }
      } else {
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    };
    const getIccid = isAuth && iccid ? "?iccid=" + iccid : "";
    const url = "countries" + getIccid;
    getCountriesList(url, headers, dispatch, getCountryData);
  }, [isAuth, iccid, headers, dispatch, trackCountry, showToast]);

  const getCatalogs = useCallback(() => {
    if (countryVal) {
      const getCatalogList = (response) => {
        if (response.result === "SUCCESS") {
          const catalogList =
            response.data && response.data.bundles ? response.data.bundles : [];
            setDoNotSell(response?.data?.doNotSell ?? false)
          if (catalogList?.length > 0) {
            let sortedCatalogs = catalogList?.sort((a, b) => a.price - b.price);
            sortedCatalogs = sortedCatalogs.map(s => {
              s.description = geteSIMsDescription(s);
              return s;
            })
            setCatalogs(sortedCatalogs);
          } else {
            showToast("info", "ESMBND-04");
          }
        } else {
          const error = response.error;
          showToast(error.severity, error.errorMsg);
        }
        setLoading(false);
      };
      if (country && country !== "") {
        setLoading(true);
        setCatalogs([]);
        const catalogDirection = direction === 1 ? "asc" : "desc";
        const catalogCountry = encodeURIComponent(country);
        const getIccid = isAuth && iccid ? "&iccid=" + iccid : "";
        const queryParameters =
          "countries=" +
          catalogCountry +
          "&page=" +
          page +
          "&limit=" +
          limit +
          "&direction=" +
          catalogDirection +
          "&orderBy=" +
          orderBy +
          "&filter=" +
          filter +
          "&filterBy=" +
          filterBy +
          getIccid;
        getCatalogsList(queryParameters, headers, dispatch, getCatalogList);
      }
    }
  }, [
    countryVal,
    country,
    showToast,
    direction,
    isAuth,
    iccid,
    orderBy,
    headers,
    dispatch,
  ]);

  useEffect(() => {
    getCountryData();
  }, [getCountryData]);

  useEffect(() => {
    getCatalogs();
  }, [getCatalogs]);

  const renderValidFor = (rowData) => {
    let plan = rowData.description?.split(",");
    let validFor = plan[2]?.trim();
    return validFor;
  };

  const onCountryChange = (e) => {
    setCountryVal(false);
    setCountry(e.value);
  };

  const onCountryHide = (e) => {
    clearStoreContent();
    const getFilteredCountry = countries?.find((x) => x.iso === country);
    trackCountry(getFilteredCountry?.name);
    setCountryVal(true);
  };

  const catalogPurchase = (catalogData) => {
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);

    const getCartObj = { ...getCartData };

    if (getCartObj !== catalogName) {
      const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay,
      };
      dispatch(cartFilterActions.setCatalogCart(setCartData));
      dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
    }
    navigate(pathname, {replace: true, state: {}})
    setCheckoutFormState(true);
  };

  const actions = (rowData) => {
    return (
      <PR.Button
        label="Top-up"
        disabled={doNotSell}
        className="action-btn"
        onClick={() => {
          setCheckoutFormState(true);
          catalogPurchase(rowData);
        }}
      />
    );
  };

  const priceBody = (rowData) => {
    return renderPrice(rowData.price);
  };

  return (
    <>
      <PR.Dialog
        className="forms-dialog payment-dialog"
        blockScroll={true}
        draggable={false}
        style={{ width: "75%" }}
        visible={checkoutFormState}
        onHide={() => setCheckoutFormState(false)}
        resizable={false}
      >
        <MakePayment
          setCheckoutFormState={setCheckoutFormState}
          setDisplayScreen={props.setDisplayScreen}
        />
      </PR.Dialog>

      <section className="esims-section">
        <div className="grid header-text">
          <div className="col-12">
            <div>
              <div className="search-box">
                <div className="grid align-items-center">
                  <div className="col-12 lg:col-6 md:col-6">
                    <h2>{labels.LBL0159}</h2>
                  </div>
                  <div className="col-12 lg:col-6 md:col-6 text-left md:text-right lg:text-right">
                    <form autoComplete="off">
                      <span className="p-input-icon-left search-field">
                        <i className="pi pi-search" />
                        <PR.Dropdown
                          filter
                          value={country}
                          options={countries}
                          onChange={(e) => onCountryChange(e)}
                          onHide={(e) => onCountryHide(e)}
                          resetFilterOnHide
                          optionLabel="name"
                          optionValue="iso"
                          placeholder={labels.LBL0160}
                        />
                      </span>
                    </form>
                  </div>
                  <EsimStatusHeader />
                </div>
              </div>
              { doNotSell && <p className='text-xs err-msg mb-5'><sup className='err-msg'>*</sup> {labels?.LBL0327}</p>}
              <div className="card">
                <div className="topup-table">
                  <PR.DataTable value={catalogs} loading={loading}>
                    <PR.Column
                      field="description"
                      header={labels.LBL0161}
                      className="first-column"
                    ></PR.Column>
                    <PR.Column
                      body={priceBody}
                      header={labels.LBL0097}
                    ></PR.Column>
                    <PR.Column
                      field="dataAmountForDisplay"
                      header={labels.LBL0024}
                    ></PR.Column>
                    <PR.Column
                      body={renderValidFor}
                      header={labels.LBL0162}
                    ></PR.Column>
                    <PR.Column
                      body={actions}
                      header={labels.LBL0163}
                    ></PR.Column>
                  </PR.DataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TopupEsims;
